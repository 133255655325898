import { Component, Input, inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
  selector: 'by-pin-input',
  templateUrl: './pin-input.component.html',
  styleUrls: ['./pin-input.component.scss'],
})
export class PinInputComponent {
  readonly nzDataModal: Partial<PinInputComponent> = inject(NZ_MODAL_DATA);

  @Input()
  description: string = this.nzDataModal?.description || '';

  @Input()
  error: string = this.nzDataModal?.error || '';

  pin = this.formBuilder.control(null, [Validators.required]);

  saving = false;

  constructor(private formBuilder: UntypedFormBuilder) {}
}
