<nz-alert
  *ngIf="description"
  class="by-mb-2"
  nzType="info"
  [nzMessage]="description"
  nzShowIcon
></nz-alert>

<form nz-form nzLayout="vertical">
  <nz-form-item>
    <nz-form-label>
      {{ 'pin' | translate | upperfirst }}
    </nz-form-label>
    <nz-form-control [nzErrorTip]="'field_required' | translate | upperfirst">
      <input
        nz-input
        type="email"
        [placeholder]="'insert_pin' | translate | upperfirst"
        [formControl]="pin"
      />
    </nz-form-control>
  </nz-form-item>
</form>

<nz-alert
  *ngIf="error"
  nzType="error"
  [nzMessage]="'error' | translate | upperfirst"
  [nzDescription]="error"
  nzShowIcon
>
</nz-alert>
