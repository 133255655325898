import { UserMe } from '../../models';

export interface State {
  data: UserMe;
  isLoading?: boolean;
  isChangePasswordLoading: boolean;
  error?: any;
}

export const initialState: State = {
  data: null,
  isLoading: false,
  isChangePasswordLoading: false,
  error: null,
};
