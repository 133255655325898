import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { item }) => ({
    ...state,
    data: item,
    isLoading: false,
    error: null,
  })),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.updateRequest, (state, { noLoading }) => ({
    ...state,
    isLoading: !noLoading,
    error: null,
  })),
  on(fromActions.updateSuccess, (state, { item }) => ({
    ...state,
    data: {
      ...state.data,
      ...item,
    },
    isLoading: false,
    error: null,
  })),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.changePasswordRequest, (state) => ({
    ...state,
    isChangePasswordLoading: true,
  })),
  on(fromActions.changePasswordSuccess, (state) => ({
    ...state,
    isChangePasswordLoading: false,
    error: null,
  })),
  on(fromActions.changePasswordFailure, (state, { error }) => ({
    ...state,
    isChangePasswordLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function userMeReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
