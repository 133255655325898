import { createFeatureSelector, createSelector } from '@ngrx/store';

import { UserMe } from '../../models';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getData = (state: State) => state.data;

export const getIsPartnerMaster = (data: UserMe) => data?.partner_master;

export const getId = (data: UserMe) => data?.id;

export const getFullName = (data: UserMe) =>
  data && `${data.first_name} ${data.last_name}`;

export const getLanguage = (data: UserMe) => data?.lang_iso_code;

export const getIsAdmin = (data: UserMe) => {
  return (
    data &&
    data.roles &&
    data.roles.findIndex((role) => role.slug === 'admin') !== -1
  );
};

export const getIsMaster = (data: UserMe) =>
  data?.permissions?.currentUser?.[0]?.type === 'master';

export const getRoles = (data: UserMe) => data && data.roles;

export const selectState = createFeatureSelector<State>('userMe');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectChangePasswordLoading = createSelector(
  selectState,
  (state) => state.isChangePasswordLoading,
);

export const selectData = createSelector(selectState, getData);

export const selectId = createSelector(selectData, getId);

export const selectFullName = createSelector(selectData, getFullName);

export const selectLanguage = createSelector(selectData, getLanguage);

export const selectIsAdmin = createSelector(selectData, getIsAdmin);

export const selectIsPartnerMaster = createSelector(
  selectData,
  getIsPartnerMaster,
);

export const selectRoles = createSelector(selectData, getRoles);

export const selectIsMaster = createSelector(selectData, getIsMaster);
